
import {
    Prop,
    Vue,Component,
    InjectReactive, PropSync, Ref
} from 'vue-property-decorator'
// @ts-ignore
import disableIcon from "@/assets/icon/disabled.png";
// @ts-ignore
import enableIcon from "@/assets/icon/enable.png";

import { cloneDeep } from "lodash";

import UploadImg from "@/components/uploadImg.vue";

import { saveOrUpdatePresCategory, presCategoryTypeList } from "@/api/prescriptionDb";


@Component({
    components: { UploadImg }
})
export default class ProductDialog extends Vue {
    @Ref("validateForm") readonly validateForm!: VForm;
    @PropSync('visible', { type: Boolean, default: false }) dialogVisible!: boolean;
    @Prop() readonly dialogTitle: string;
    @Prop({ default: () => ({}) }) readonly productInfo: any;

    @InjectReactive() merchantId!: string|number;

    productTypeOptions = [{ label: "项目产品库", value: 1 }, { label: "居家产品库", value: 2 }, { label: "其他库", value: 3 }]

    disableIcon = disableIcon;
    enableIcon = enableIcon;

    rules = {
        name: { required: true, validator: this.validatorProductName, message: '请输入产品名称'},
        effect: { required: true, validator: this.validatorGx, message: '请输入产品功效，30-400字'},
        img: { required: true, type: "array", message: "请上传图片" }
    }

    //  修改产品名称的 form
    productFormData = {
        id: undefined,
        parentId: undefined,
        name: "",
        productRemark: "",
        effect: "",
        docSource: "",
        img: [],
        status: true,
    }

    btnLoading = false

    // 二级 设备类型名称
    get categoryTypeName() {
        const option = this.productTypeOptions.find((item) => item.value == this.productInfo.parentId)
        return option?.label;
    }

    validatorProductName(rule, value, callback) {
        const _value = value?.trim();
        _value ? callback() : callback(new Error())
    }
    validatorGx(rule, value, callback) {
        const _value = value?.trim();
        if (_value?.length >= 30 && _value?.length <= 400) {
            callback();
        }else {
            callback(new Error())
        }
    }

    dialogClose() {
        this.dialogVisible = false;
        this.productFormData.id = "";
        this.productFormData.productRemark = "";
        this.productFormData.img = [];
        this.validateForm?.resetFields();
    }

    relatedDialogClose() {
        // this.relatedDialogVisible = false;
        // this.handleRemove();
        // this.disabledTag = false;
    }
    dialogConfirm() {
        this.validateForm?.validate().then(() => {
            const params = cloneDeep(this.productFormData);
            
            params.categoryType = this.productInfo?.categoryType + 1;
            params.sort = 0;
            params.parentId = this.productInfo?.id;
            params.status = Number(params.status);
            params.majorName = params.name;
            params.deviceClassId = this.productInfo.deviceClassId;
            params.img = params.img[0]?.url;
            Object.assign(params, { merchantId: this.merchantId, ownerType: this.merchantId ? 1: 0 })
            this.btnLoading = true;
            saveOrUpdatePresCategory(params).then(() => {
                // this.refreshProductList();
                this.dialogClose();
                this.$emit("refreshProductList")
            }).finally(() => {
                this.btnLoading = false;
            })
        })
    }

    
    
}


import {
    Prop,
    Component,
    Vue,
    PropSync,
    InjectReactive,
    Watch
} from 'vue-property-decorator'

import RichText from "@/components/rich-text.vue";

import { cloneDeep } from 'lodash';

import { saveOrUpdatePresCategory, getCategoryDetail } from "@/api/prescriptionDb";


@Component({
    components: {
        RichText,
    }
})
export default class ProductDetail extends Vue {
    @PropSync("visible", { default: false }) dialogVisible !: boolean;
    @Prop() productInfo !: any;

    @InjectReactive()
    merchantId !:number;

    productFormData = {} as any;

    loadingDetail = false;

    dialogClose() {
        this.dialogVisible = false;
        this.productFormData = {};
    }

    dialogConfirm() {
        const params = cloneDeep(this.productFormData);
        params.categoryType = this.productInfo?.categoryType + 1;
        params.sort = 0;
        params.parentId = this.productInfo?.id;
        params.status = Number(params.status);
        params.majorName = params.name;
        params.deviceClassId = this.productInfo.deviceClassId;
        params.img = params.img[0]?.url;
        Object.assign(params, { merchantId: this.merchantId, ownerType: this.merchantId ? 1: 0 })
        // this.btnLoading = true;
        saveOrUpdatePresCategory(params).then((res) => {
          this.dialogClose();
        })
    }

    @Watch("dialogVisible")
    getCategoryDetail() {
        if (!this.dialogVisible) return;
        this.loadingDetail = true;
        getCategoryDetail(this.productInfo.id).then((res) => {
            this.productFormData = { ...this.productInfo, id: this.productInfo.id, status: this.productInfo.id ? Boolean(this.productInfo.status) : true, productRemark: res.data?.productRemark, img: this.productInfo.img ? [{ url: this.productInfo.img }] : [] };
        }).finally(() => {
            this.loadingDetail = false;
        })
    }
}
